import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { WrapPageElementBrowserArgs } from 'gatsby'
import { defaultLanguage, availableLanguages } from '../locales'
import LanguageDetector from 'i18next-browser-languagedetector'

const i18nOptions = {
  fallbackLng: defaultLanguage,
  supportedLngs: availableLanguages.map(lng => lng.key),
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  initImmediate: false,
}

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  const i18n = i18next.createInstance()
  i18n.use(LanguageDetector).init({ ...i18nOptions })
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}
