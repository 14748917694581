const pathToRegexp = require('path-to-regexp')
const defaultLanguage = 'ja-jp'
const availableLanguages = [
  {
    key: 'ja-jp',
    label: '日本語',
  },
  {
    key: 'zh-tw',
    label: '繁體中文',
  },
  {
    key: 'en-us',
    label: 'English',
  },
]
const noTranslationRegexps = ['/:lang?/essays/:uid?'].map(path =>
  pathToRegexp(path)
)

function shouldHaveTranslation(path) {
  return !noTranslationRegexps.some(regexp => regexp.test(path))
}

function generatePath(originalPath, lang) {
  const sanitizedPath = availableLanguages.reduce((acc, cur) => {
    return acc.replace(`/${cur.key}`, '')
  }, originalPath)
  return `${
    shouldHaveTranslation(originalPath) && lang !== defaultLanguage
      ? `/${lang}`
      : ''
  }${sanitizedPath}`
}

module.exports = {
  defaultLanguage,
  availableLanguages,
  shouldHaveTranslation,
  generatePath,
}
